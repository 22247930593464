<template>
  <div class="travel">
    <div class="head_img">
      <div class="img_title">
        <div class="img">
          <img class="img" v-if="topinfo.projectImg" :src="demandinfo.projectImg" alt="" />
          <img class="img" v-else src="../../../assets/image/Group 3163.png" alt="" />
        </div>
        <div class="title">
          <p class="merchant_name">{{ topinfo.projectName }}</p>
          <div class="interval">
            <span style="position: relative; left: -5px; color: #078bff">
              <img src="../../../assets/image/jr-icon-zhong.png" width="14" height="14" alt="" />
            </span>
            <span>时间：{{ topinfo.startTime }}至{{ topinfo.endTime }}</span>
          </div>
          <div class="interval" style="padding: 7px 0px">
            <span style="position: relative; left: -1px; top: 2px">
              <img src="../../../assets/image/jr-icon-xingxing.png" width="14" height="14" alt="" />
            </span>
            <span style="margin-left: 3px">{{ demandinfo.realName }}</span>
            <span v-if="demandinfo.chamberCommerceName"
              >({{ demandinfo.chamberCommerceName }})</span
            >
            <span v-if="demandinfo.chamberCommerceJob"> ({{ demandinfo.chamberCommerceJob }})</span>
          </div>
          <div class="interval">
            <span style="position: relative; left: -5px; top: 2px">
              <img src="../../../assets/image/jr-icon-dianhua.png" width="14" height="14" alt="" />
            </span>
            <span>{{ demandinfo.phone }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="travel_con">
      <div class="travel_button">
        <div style="display: flex">
          <el-button type="primary" @click="handleAdd">新增行程</el-button>
          <!-- <el-button type="primary" plain @click="upfu">上传附件</el-button> -->
          <!-- <el-button @click="accessory">附件记录</el-button> -->
          <div class="top_info" style="margin-left: 20px">
            <span style="position: relative; top: 2px">
              <img src="../../../assets/image/Volume Up.png" alt="" />
            </span>
            <div class="top_time" style="padding-right: 30px">
              <div>
                <span style="color: #75757d">最新更新时间：</span>
                <span>{{ demandinfo.updateTime }}</span>
              </div>

              <div>
                <span style="color: #75757d">更新人：</span>
                <span>{{ demandinfo.updateName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <el-button type="warning" @click="handleTrip">发布行程</el-button>
        </div>
      </div>
      <div class="scheduling" v-loading="loading">
        <div class="step">
          <div :style="stepheight">
            <el-steps direction="vertical" :active="1">
              <el-step v-for="(item, index) in list" :key="index" title=""></el-step>
            </el-steps>
          </div>
        </div>
        <div class="trip">
          <div class="trip_information" v-for="(item, index) in list" :key="index">
            <div style="background: #fafafa; padding: 0px 20px">
              <div class="trip_information_top">
                <div style="display: flex" class="trip_name_left">
                  <span style="color: #146aff; font-size: 18px">{{ item.tripName }}</span>
                  <div class="hotelLogo" v-if="item.tripType == '3'">
                    <img :src="item.hotelLogo" alt="" />
                    <span>{{ item.hotelName }}</span>
                  </div>
                  <div class="companyLogo" v-if="item.tripType == '1'">
                    <img :src="item.companyLogo" alt="" />
                    <span>{{ item.companyName }}</span>
                  </div>
                </div>
                <div class="trip_name_right">
                  <el-switch
                    v-model="item.isAvailable"
                    @change="editmanagement(item)"
                    active-color="#3562ff"
                    inactive-color="#d9d9d9"
                    :active-value="'1'"
                    :inactive-value="'0'"
                  >
                  </el-switch>
                  <span style="padding-left: 10px; color: #9b9a9a" v-if="item.isAvailable == 1"
                    >已启用</span
                  >
                  <span style="padding-left: 10px; color: #9b9a9a" v-else>未启用</span>
                  <span>
                    行程时间： <span style="color: #fea15f">{{ item.startTime }}</span></span
                  >
                </div>
              </div>
            </div>
            <div class="trip_information_bottom">
              <div class="trip_name_left">
                <span v-if="item.tripType == '1'" style="margin-right: 40px">
                  <img src="../../../assets/image/jr-icon-renmen.png" alt="" />
                  <span class="txt">记录员：</span>
                  <span>{{ item.recorder }}</span>
                </span>

                <img src="../../../assets/image/jr-icon-direction.png" alt="" />
                <span style="margin-top: 6px">
                  <span class="txt">详细地址：</span>
                  <span>{{ item.tripLocation }}</span>
                </span>
              </div>
              <div class="trip_name_right">
                <el-button
                  v-if="item.tripType == '1'"
                  type="primary"
                  size="mini"
                  plain
                  @click="handlerecord(item)"
                  >行程记录</el-button
                >
                <el-button
                  type="text"
                  icon="el-icon-edit-outline"
                  @click="handeitd(item)"
                ></el-button>

                <el-button type="text" icon="el-icon-delete" @click="delhand(item)"></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="上传附件" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="tripAccessory" label-width="80px">
        <el-form-item label="附件名称" prop="">
          <el-input v-model="tripAccessory.accessoryName" placeholder="请输入附件名称" />
        </el-form-item>
        <el-form-item label="附件">
          <el-input v-model="filename" placeholder="" />
          <el-upload
            action
            class="upload-demo"
            ref="upload"
            :show-file-list="false"
            :before-upload="
              (res) => {
                return beforeUpload(res, index)
              }
            "
            :http-request="
              (res) => {
                return upload(res, index)
              }
            "
          >
            <el-button slot="trigger" size="mini" type="danger">选取文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog title="附件记录" :visible.sync="flag" append-to-body>
      <div style="width: 400px; padding: 20px 0px">
        <el-input
          placeholder="请输入附件名称"
          v-model="queryInfo.accessoryName"
          class="input-with-select"
        >
          <el-button
            style="
              border: 1px solid #448aff;
              border-radius: 0;
              background-color: #448aff;
              color: #fff;
            "
            slot="append"
            @click="search()"
            >搜索</el-button
          >
        </el-input>
      </div>
      <el-table ref="multipleTable" :data="historyList" row-key="id">
        <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
        <el-table-column label="附件名称" align="center" prop="accessoryName" />
        <el-table-column label="附件文件" align="center" prop="accessoryAddress" />
        <el-table-column label="上传人" align="center" prop="createTime" />
        <el-table-column label="上传时间" align="center" prop="updateTime" />
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="histotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog> -->
    <el-dialog title="新增" :visible.sync="visible" width="500px" append-to-body>
      <el-form ref="forms" :model="form" :rules="rules" label-width="85px">
        <el-form-item label="行程日期:" prop="trveldate">
          <div style="font-size: 14px">{{ topinfo.startTime }} 至 {{ topinfo.endTime }}</div>
          <el-radio-group v-model="form.trveldate">
            <el-radio v-for="(item, index) in trvellist" :key="index" :label="item.time"
              >{{ item.day }}号</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="行程时间:" prop="trveltime">
          <el-time-picker
            v-model="form.trveltime"
            value-format="HH:mm"
            format="HH:mm"
            placeholder="任意时间点"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancels">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  managementlist,
  releaseTrip,
  getdemandtop,
  editmanagement,
  delmanagement,
  accessorylist,
  getdemand,
  addtripAccessory,
  editAvailable
} from '@/api/demand'
import { uploadAdmin } from '@/api/common'
export default {
  name: 'TravelConfiguration',
  computed: {
    inspectDemandId() {
      return this.$route.query.inspectDemandId * 1
    }
  },
  data() {
    return {
      list: [],
      stepheight: {
        height: ''
      },
      demandinfo: {}, //最近更新
      topinfo: {}, //头部
      open: false,
      loading: false,
      flag: false,
      tripAccessory: {
        accessoryAddress: '',
        accessoryName: '',
        id: null,
        inspectDemandId: this.$route.query.inspectDemandId
      }, //上传附件
      filename: null,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        accessoryName: null,
        inspectDemandId: this.$route.query.inspectDemandId
      }, //附件记录
      historyList: [],
      histotal: 0, //记录条数
      visible: false, //选择日期
      form: {}, //选择的行程日期数据
      trvellist: [],
      //验证必填
      rules: {
        trveldate: [{ required: true, message: '请勾选日期', trigger: 'change' }],

        trveltime: [{ required: true, message: '请选择时间点', trigger: 'change' }]
      }
    }
  },
  created() {
    this.managementlist()
    this.getdemandtop()
    this.getdemands()
  },
  methods: {
    //最近更新时间
    async getdemandtop() {
      const res = await getdemandtop({ inspectDemandId: this.inspectDemandId })
      if (res.data.resultCode == 200) {
        this.demandinfo = res.data.data
        this.demandinfo.areasInvestigation = JSON.parse(this.demandinfo.areasInvestigation)
      }
    },
    //获取信息
    async getdemands() {
      const res = await getdemand(this.inspectDemandId)
      if (res.data.resultCode == 200) {
        this.topinfo = res.data.data
        console.log(this.topinfo)
        var start = new Date(this.topinfo.startTime)
        var end = new Date(this.topinfo.endTime)
        var time = new Date(end.getTime() - start.getTime())

        for (var i = 0; i < time.getDate(); i++) {
          let a = new Date(start.getTime() + 1000 * 60 * 60 * 24 * i)
          var day = a.getDate()
          var month = a.getMonth() + 1
          var year = a.getFullYear()
          if (day < 10) {
            day = '0' + day
          }
          if (month < 10) {
            month = '0' + month
          }
          var rq = `${year}-${month}-${day}`
          this.trvellist.push({
            day: day,
            time: rq
          })
        }
      }
    },
    //行程一览
    async managementlist() {
      this.loading = true
      const res = await managementlist({ inspectDemandId: this.inspectDemandId })
      if (res.data.resultCode == 200) {
        this.list = res.data.data
        this.loading = false
        this.serach()
      }
    },
    //新增行程
    handleAdd(item) {
      this.visible = true
      // this.$router.push({
      //   name: 'addtrip',
      //   query: { inspectDemandId: this.inspectDemandId }
      // })
    },
    //编辑行程
    handeitd(item) {
      this.$router.push({
        name: 'addtrip',
        query: { inspectDemandId: this.inspectDemandId, id: item.id }
      })
    },
    //上传附件
    upfu() {
      this.open = true
    },
    accessory() {
      this.flag = true
      this.accessorylist()
    },
    //附件记录
    async accessorylist() {
      const res = await accessorylist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.historyList = res.data.data.list
        this.histotal = res.data.data.total
      }
    },
    //取消
    cancel() {
      this.open = false
    },
    async submit() {
      const res = await addtripAccessory(this.tripAccessory)
      if (res.data.resultCode == 200) {
        this.$message.success('上传成功')
        this.open = false
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    //上传pdf
    async beforeUpload(file, index) {
      this.file = file
      this.filename = file.name

      const extension = file.name.split('.').splice(-1) == 'pdf'
      if (!extension) {
        this.$message.warning('上传模板只能是pdf格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    async upload(params, index) {
      const form = new FormData()
      form.append('file', params.file)

      const res = await uploadAdmin(form)
      if (res.data.resultCode === 200) {
        this.tripAccessory.accessoryAddress = res.data.data
      }
    },
    //行程记录
    handlerecord(item) {
      this.$router.push({
        name: 'Journeyrecord',
        query: { inspectDemandId: this.inspectDemandId, id: item.id }
      })
    },
    //发布行程
    handleTrip() {
      const ids = this.inspectDemandId
      this.$alert('您是否确认发布行程？')
        .then(function () {
          return releaseTrip({ inspectDemandId: ids })
        })
        .then(() => {
          this.$message.success('发布成功')
          this.$router.go(-1)
        })
        .catch(() => {})
    },
    //删除行程
    delhand(row) {
      const ids = row.id
      this.$alert('您是否确认删除？')
        .then(function () {
          return delmanagement({ id: ids })
        })
        .then(() => {
          this.managementlist()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },
    //启用修改
    editmanagement(item) {
      editAvailable(item).then((response) => {
        this.managementlist()
      })
    },
    serach() {
      if (this.list.length == 2) {
        this.stepheight.height = 160 + 'px'
      } else {
        this.stepheight.height = 135 * (this.list.length - 1) + 'px'
      }
    },
    submitForm() {
      this.$refs['forms'].validate((valid) => {
        if (valid) {
          console.log(this.form)
          this.$router.push({
            name: 'addtrip',
            query: {
              inspectDemandId: this.inspectDemandId,
              startTime: `${this.form.trveldate} ${this.form.trveltime}`
            }
          })
        }
      })
    },
    cancels() {
      this.visible = false
      this.form = {}
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-step__icon.is-text {
  border-radius: 50%;
  border: 1px solid;
  color: #ffffff;
  border-color: #146aff;
  background: #146aff;
}
::v-deep .el-step__line {
  position: absolute;
  border-color: #ffffff;
  border: 1px dashed #b6d0ff;
  background-color: #ffffff;
}
::v-deep .el-step.is-vertical .el-step__line {
  width: 0px;
  top: 0;
  bottom: 0;
  left: 11px;
}
.travel {
  .storeCenter_item_top_left_flex_tag {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    padding-top: 13px;

    span {
      box-sizing: border-box;
      padding: 1px 6px;
      display: block;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
    }

    .office {
      position: relative;
      left: -3px;
      padding: 5px;
      color: #6090ee;
      background: #e1ecff;
    }

    .support {
      border: 1px solid #fd523f;
      color: #fd523f;
      margin-left: 12px;
    }
  }
  .top_info {
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 17px;
    }
    .top_time {
      display: flex;
      justify-content: space-around;
      font-size: 14px;
      width: 356.84px;
      height: 38px;
      background: #fafafa;
      border-radius: 8px;
      line-height: 38px;
    }
  }
  .interval {
    padding-left: 5px;
  }
  .head_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 15px 10px;
    background: #ffffff;
    .storeCenter_item_top_center {
      width: 600px;
    }
    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      .img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
      }

      .title {
        margin: 0px 0px 0px 10px;
        display: flex;
        flex-direction: column;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -5px;
        }

        .merchant_type {
          position: relative;
          top: 10px;
        }
      }
    }
  }
  .travel_con {
    width: 100%;
    margin-top: 20px;
    background: #ffffff;
    .travel_button {
      display: flex;
      justify-content: space-between;
      padding: 30px 20px 0px 20px;
    }
    .scheduling {
      position: relative;
      background: #ffffff;
      padding-bottom: 30px;
      .step {
        padding: 30px 20px;
        position: absolute;
        left: 20px;
      }
      .trip {
        background: #ffffff;
        padding-top: 30px;
      }
      .trip_information {
        height: 109px;
        padding: 0px 80px;
        margin-bottom: 20px;
        .hotelLogo {
          display: flex;
          align-items: center;
          padding: 0px 20px;
          img {
            width: 20px;
            height: 20px;
            border: 1px solid #ff862e;
            border-radius: 2px;
          }
          span {
            padding-left: 10px;
            color: #ff862e;
          }
        }
        .companyLogo {
          display: flex;
          align-items: center;
          border-radius: 2px;
          border: 1px solid #ff862e;
          margin-left: 20px;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            text-align: center;
            padding: 0px 5px;
            font-size: 12px;
            background: #ff862e;
            color: #ffffff;
          }
        }
        .trip_information_top {
          padding: 15px 0px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #efefef;
          .trip_name_right {
            font-size: 14px;
          }
        }
        .trip_information_bottom {
          background: #fafafa;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 20px;
          .trip_name_left {
            font-size: 14px;
            display: flex;
            align-items: center;

            img {
              width: 18px;
              height: 20px;
            }
            .txt {
              padding-left: 10px;
              color: #9b9a9a;
            }
          }
        }
      }
    }
  }
}
</style>
